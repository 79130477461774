@import url('https://fonts.googleapis.com/css2?family=Varela_Round');

* {
    font-family: "Varela_Round", Arial, Helvetica, sans-serif;
}
html {
    background: white !important;
}

#overlay {
    width: 100%;
    position: absolute; /*You may also use fixed depending on your needs.*/
    left: 0;
    top: 30px; /*If you want it at the bottom you probably want bottom:0 instead*/
    z-index: 999;
}

#overlay2 {
    position: absolute; /*You may also use fixed depending on your needs.*/
    /*If you want it at the bottom you probably want bottom:0 instead*/
    z-index: 999;
    left: 500px;
    height: 150px;
    bottom: 40px;
}


.swal2-popup #swal2-content {
    text-align: center;
    font-size: x-large;
    color: red;
    font-weight: 600;
}

/* You can add global styles to this file, and also import other style files */


.red{
    border: 5px solid red;
    border-radius: 7px;
}


.blue{
    border: 1px solid rgba(54, 55, 56, 0.65)

}

.mat-dialog-container {
   width:  400px!important;
}

.mat-icon {
    cursor: pointer;
}
.red2{
    color : red;
    font-weight: bolder;
    margin-left: 10px;
}


.redtext{
    color : red;
    font-weight: bolder;
}
.my-custom-class-one{
    background: red;
}

.blue3{
    background: #1976d2;
    color: white;

}

.blue2{


}

.solar {
    background: url('assets/img/solar.png');
    height: 20px;
    width: 20px;
    display: block;
    /* Other styles here */
}

.dataTables_wrapper div.dataTables_filter input {
    width: 300px !important;
}


mat-error{
    font-weight: 900;
    font-size: 15px;
    color: red;

}

.amcharts-export-menu{
    display: none;
}


.amcharts-main-div a{
    display: none!important;
}

.bmd-label-floating, .bmd-label-placeholder{

}
.pointer {cursor: pointer;
}

.mat-radio-label-content{
    white-space: pre-wrap !important;
}

 h1, h3, h2 {
    color: #0e0101 !important;
    font-weight: bold !important;
}

table{
    color: #0e0101 !important;
    font-weight: bold !important;
}

small, .small {

    font-weight: bold !important;

}
.text-muted, .bmd-help {
    color: #040e01 !important;
    font-weight: bold !important;
}

body {
    color: #0e0101 !important;
    font-weight: bold !important;
}


.close {
    font-size: 55px !important;
    line-height: 1 !important;
    color: white !important;
    opacity: 100 !important;
}

.bb {
    break-inside: avoid;
    margin-bottom: 50px;
}

.ais-Hits-list {
    margin-top: 0;
    margin-bottom: 1em
}

.ais-SearchBox {
    margin-bottom: 1em
}

.ais-Pagination {
    margin-top: 1em
}

.left-panel {
    float: left;
    width: 250px
}

.right-panel {
    margin-left: 260px
}

.ais-InstantSearch {
    max-width: 960px;
    overflow: hidden;
    margin: 0 auto
}

.ais-Hits-item {
    margin-bottom: 1em;
    width: calc(50% - 1rem)
}
